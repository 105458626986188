
              @import "@/scss/variables.scss";
          

@import '@/scss/variables.scss';

    table {
        width: 100%;
        // margin: auto;
        // min-width: 50%;
        font-size: 14px;

        th,
        td {
            padding: 2px 10px;
            text-align: right;
            &:first-child {
                text-align: left;
            }
        }

        th {
            color: #fff;
            background: #555;
            line-height: 1.2em;
            &:first-child {
                white-space: pre;
            }
        }

        td {
            font-size: 14px;
            border-bottom: 1px solid #ddd;
            vertical-align: middle;

            &:first-child {
                font-weight: 500;
                background-color: #f7f7f7;
            }
            &.fixed-column-Header {
                background-color: #f7f7f7;
            }
        }

        .top-table-headers {
            th {
                text-align: center;
                background-color: #717171;
                border-left: 2px solid #555;

                &:first-child {
                    border: none;
                }
            }
        }
    }

    .report-table-wrapper {
        position: relative;
        overflow: auto;
        width: 100%;
    }

    .floated-table-header {
        position: absolute;
        top: 0;
        left: 0;

        th,
        td {
            position: relative;
            background: none;
            z-index: 1;
            text-align: left;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 100%;
                width: 15px;
                height: 100%;
                z-index: -1;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #ccc;
                z-index: -1;
            }
        }

        th {
            background-color: #757575;
            &:before {
                background: linear-gradient(to right, rgba(85,85,85,1) 0%,rgba(85,85,85,0) 100%);
            }
            &:after {
                background-color: #757575;
            }
        }

        td {
            background-color: #f7f7f7;
            &:before {
                background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            }
            &:after {
                background-color: #f7f7f7;
            }
            // &.highlight-1,
            // &.highlight-2,
            // &.highlight-3 {
            //     ~ td {
            //         padding-left: 6em;
            //     }
            // }
        }
    }

    table,
    .floated-table-header {
        td {
            &.highlight-strong,
            &.highlight-1,
            &.highlight-2,
            &.highlight-3 {
                &:before {
                    background: linear-gradient(to right, rgba(0,0,0,0.05) 0%,rgba(0,0,0,0) 100%);
                }
            }
            &.highlight-strong {
                color: #fff;
                &,
                &:after {
                    background-color: $blue;
                }
                &:before {
                    background: linear-gradient(to right, rgba(0,0,0,0.05) 0%,rgba(0,0,0,0) 100%);
                }
            }
            &.highlight-1 {
                &,
                &:after {
                    background-color: #b1d1f1;
                }
                &:before {
                    background: linear-gradient(to right, rgba(0,0,0,0.05) 0%,rgba(0,0,0,0) 100%);
                }
            }
            &.highlight-2 {
                padding-left: 1.5em;
                &,
                &:after {
                    background-color: #d3e6f9;
                }
            }
            &.highlight-3 {
                padding-left: 3em;
                &,
                &:after {
                    background-color: #edf6ff;
                }
            }
            &.highlight-3-end {
                padding-left: 3em;
            }
            &.highlight-4 {
                padding-left: 4.5em;
            }
        }
    }

    #report-modal {
        .report-footnotes-wrapper {
            max-height: 100%;
            padding-bottom: 0;
            position: initial;
            color: #212529;
             &:before {
                 display: none;
             }
        }

        .last-updated {
            position: relative;
            left: 0;
            bottom: 0;
            padding-top: 10px;
        }
    }

    .modal.fade {
        .floated-table-header {
            opacity: 0;
            &.shown {
                opacity: 1;
            }
        }
    }
    // .level2 {
    //     font-weight: 700;
    //     // color: $green;
    //     // background-color: #eee;
    // }
    // .level3 {
    //     font-weight: 500;
    //     // color: $green;
    // }

