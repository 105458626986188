
.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader {
    // display: none;
    color: $loader-color;
    font-size: 3px;
    left: calc(50% - 5em);
    top: 100px;
    position: absolute;
    text-indent: -9999em;
    border-top: 1.1em solid $loader-border;
    border-right: 1.1em solid $loader-border;
    border-bottom: 1.1em solid $loader-border;
    border-left: 1.1em solid #0f3068;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    z-index: 10;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

// custom
.loader {
    position: absolute;
}

#dashboard-loader {
    position: relative;
    display: inline-block;
    left: auto;
    top: auto;
    margin-left: 10px;
    margin-top: -6px;
    vertical-align: middle;
}