
              @import "@/scss/variables.scss";
          

table th, table td {
    text-align: left;
}
table td:first-child {
    background-color: transparent;
}
