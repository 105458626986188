
              @import "@/scss/variables.scss";
          

#report-index-view {
    .search-highlight {
        background-color: #ffe98c;
    }
    $toggle-radius: 4px;
    .btn-toggle-wrapper {
        border-radius: $toggle-radius $toggle-radius $toggle-radius $toggle-radius;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }
    .btn-toggle {
        display: inline-block;
        padding: 4px 10px;
        font-size: 16px;
        background-color: #fff;
        border: none;
        outline: none;
        border-radius: $toggle-radius 0 0 $toggle-radius;
        cursor: pointer;
        + .btn-toggle {
            border-radius: 0 $toggle-radius $toggle-radius 0;
        }
        &.selected {
            color: #fff;
            background-color: $blue;
        }
        .arrow {
            display: none;
        }
        &.desc {
            .arrow {
                transform: rotate(180deg);
                display: inline;
                fill: #fff;
            }
        }
        &.asc {
            .arrow {
                display: inline;
                fill: #fff;
            }
        }
    }

    .filter-col {
        width: 500px;
    }
    .filter-wrapper {
        position: relative;
        .filterby {
            position: relative;
            width: 100%;
            border: none;
            border-radius: 3px;
            padding: 0.35em 0.5em;
            transition: 0.3s;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
            outline: none;
            z-index: 5;
            &:focus {
                // width: 300px;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
                ~ .search-button {
                    pointer-events: all;
                    svg {
                        fill: darken($category-header-background, 20%);
                    }
                    &:hover,
                    &:focus {
                        svg {
                            fill: $category-header-background;
                        }
                    }
                }
            }
        }
        .search-button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 4px 6px;
            height: 31px;
            background: none;
            border: none;
            outline: none;
            pointer-events: none;
            z-index: 6;
            &.search-button-mobile {
                pointer-events: auto;
            }
            .search-icon {
                width: 23px;
                height: 23px;
                fill: $category-header-background;
                transition: 0.3s;
            }
        }
        
    }
    .sort-header {
        font-weight: bold;
        font-size: 18px;
        color: $grey-dark;
    }
    .report-list {
        a {
            font-size: 18px;
            font-weight: 500;
            text-decoration: none;
        }
        .report-info {
            color: $grey;
            font-size: 14px;
            font-weight: 600;
            span {
                // color: $grey;
                // font-weight: normal;
                a {
                    font-size: 14px;
                    color: $grey;
                    // text-decoration: underline;
                    text-decoration: none;
                    background-color: #fff;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
                    border-radius: 12px;
                    padding: 2px 6px;
                    margin-right: 10px;
                    line-height: 2.2em;
                    transition: 0.3s;
                    &:hover, &:focus {
                        // text-decoration: none;
                        color: $grey-dark;
                        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }
    }
    .no-records {
        color: $grey-medium;
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
    }
}
