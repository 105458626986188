
              @import "@/scss/variables.scss";
          

.mark-body {
    > p {
        background-color: rgb(243, 213, 243);
    }
    a {
        text-decoration: none !important;
    }
}
.download-list {
    list-style: none;
    margin: 30px 0;
    padding: 0;

    .heading {
        font-size: 16px;
        color: #333;
    }

    > li {
        margin-bottom: 20px;
    }
}

.btn-download {
    display: inline-block;
    padding: 6px 10px;
    background-color: #fff;
    color: #ad1700;
    // border: 1px solid #ab1800;
    border-radius: 4px;
    transition: 0.3s;

    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    }

    img {
        margin-right: 5px;
    }
}
