
              @import "@/scss/variables.scss";
          

    .legends {
        margin-bottom: 4px;
        padding: 0;
        list-style: none;
        .legend-bar {
            .circle {
                height: 1.2rem;
                width: 1.2rem;
                position: absolute;
                top: 2px;
                left: 4px;
            }
            .color-swatch {
            top: 3px;
            left: 5px;
            height: 15px;
            width: 15px;
            border-radius: 3px;
            }
        }
        li {
            display: inline-block;
            position: relative;
            font-size: 13px;
            .legend-item {
                display: inline-block;
                border: none;
                background-color: transparent;
                padding: 1px 4px 2px;
                span {
                    padding-left: 20px;
                    padding-right: 3px;
                }

                &.unselected {
                    color: #bbb;
                    svg {
                        fill: #999;
                    }
                    .color-swatch {
                        background-color: #999 !important;
                    }
                }
                &:hover, &:focus {
                    outline: none;
                    background-color: #eee;
                    border-radius: 5px;
                }
            }
            .circle {
                height: 1.2rem;
                width: 1.2rem;
                position: absolute;
                top: 2px;
                left: 4px;
            }
            .color-swatch {
                position: absolute;
                top: 3px;
                left: 5px;
                height: 16px;
                width: 16px;
                border-radius: 50%;
            }
        }
    }
