// $blue: #275d94;
$blue-nih: #20558a;
$blue: $blue-nih;
$blue-dark: #102f68;
// $blue-light: #3769c7;
// $blue-light: #1978c1;
$blue-light: #0566b1;
$blue-lighter: #6ca2d9;
$blue-lightest: lighten($blue, 55%);
$yellow: #ecc343;
$yellow-light: #faf2d9;
$teal: #1d9f91;
$red: #b30d39;
$purple: #6043ac;
$brown: #865105;
// $link: #252F6C;
$link: $blue;
$arrow: #99aed8;
$arrow-hover: #8396b9;

$grey-dark: #333;
$grey: #555;
// $grey-medium: #777;
$grey-medium: #757575;
$grey-medium-light: #b7b7b7;
$grey-light: #bbb;
$grey-lighter: #cecece;
$grey-lightest: #f2f2f2;

$gray-darker:            lighten(#000, 13.5%); // #222
$gray-dark:              lighten(#000, 20%);   // #333
$gray:                   lighten(#000, 33.5%); // #555
$gray-med-light:         #7b7b7b;
$gray-light:             lighten(#000, 60%);   // #999
$gray-lighter:           lighten(#000, 93.5%); // #eee


$secondary:     $gray;

$white:                 #FFFFFF;
$primary:               #015496;
$active-row-color:      desaturate(lighten($primary,62%),40%);
$green:                 #087235;
$green-light:           #4cad4c;
$red:                   #D23800;
$red-dark:              #641414;
$gray-nih:              #56575a;
$gray-border:           #bfbfbf;

$bg-color: #ececec;

$widget-loader-color: $grey-lighter;

$arrow-widget: 18px;
$widget-bg-color: #fff;
$widget-accent-color: #555;
$widget-settings-background-color: #E0E0E0;
$widget-radius: 2px;
$widget-settings-tab-radius: 2px;
$widget-spacing: 30px;
$widget-dashboard-padding: 10px;

$button-radius: 3px;

$header-height: 44px;
$logo-width: 50px;
$logo-height: 32px;

// $demo-widget-color: #1e8c50;
$demo-widget-color: #0c85c1;

$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

// $title-font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
$title-font-family: "Roboto", Helvetica, Arial, sans-serif;

$tran-speed: .3s;

$loader-color: $grey-lighter;
$loader-border: #ccc;
$loading-opacity: .6;
$emulating-bg: #e40000;

$sidebar-width: 330px;

$content-background: #F3F3F3;
$category-header-background: #DEDEDE;
$hover-category-header-background: #c6dcef;
$active-category-header-background: #0666B1;
$report-list-background: #E8E8E8;

$footnotes-transition: 0.5s;