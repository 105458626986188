html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    // overflow-x: hidden;
    position: relative;


    background-color: $bg-color;
    font-size: 14px;
    font-family: $base-font-family;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// html {
    // overflow: hidden;
// }
/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }


#app {
    height: 100%;

    @media only screen and (max-width: 768px) {
        height: auto;
    }
}

.nowrap {
    white-space: nowrap;
}


.breakall {
    word-break: break-all;
}

h1, h2, h3, h4, h5, h6, .heading {
    font-family: $title-font-family;
    font-weight: 500;
    color: $blue-light;
}

#main {
    position: relative;
    min-height: 100%;
}

.header-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    #applicationMsgInfo {
        .btn {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1;
            color: #000;
            text-shadow: 0 1px 0 #ffffff;
            opacity: .5;
        }
    }
}

#header {
    height: $header-height;
    background-color: $blue-nih;
    color: #fff;
    // font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    padding: 6px 10px;
    z-index: 100;
    width: 100%;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    // h1 {
    //     display: inline-block;
    //     margin: 0 0.1em 0 0.1em;
    //     font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    //     font-size: 22px;
    //     font-weight: 400;
    //     vertical-align: middle;
    //     line-height: 1em;
    //     color: #fff;
    // }
    a {
        color: #fff;
        text-decoration: none;
    }

    .link-text {
        display: inline-block;
        margin: 0 0.1em 0 0.1em;
        font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
        font-size: 22px;
        font-weight: 400;
        vertical-align: middle;
        line-height: 1em;
    }
}

.logo {
    display: inline-block;
    width: 50px;
    height: 32px;
    vertical-align: middle;
    fill: #fff;
}

.divider-arrow {
    width: 10px;
    height: 30px;
    fill: #fff;
    fill: rgba(255,255,255,0.5);
    vertical-align: middle;
    margin: 0 3px;
}

#sidebar {
    position: fixed;
    top: $header-height;
    left: 0;
    width: $sidebar-width;
    // height: calc(100vh - #{$header-height});
    // background-color: #fff;
    // overflow: auto;
    background-color: #f7f7f7;
    // box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

    z-index: 20;
    transition: 0.3s;

    @include media-breakpoint-down(sm) {
        // box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
    }

    &.hide {
        left: -$sidebar-width;

        .menu-icon {
            transform: rotate(180deg);
        }

        + #content,
        ~ #footer {
            padding-left: 0;

            // @include media-breakpoint-down(lg) {
            //     padding-left: $sidebar-width * 0.9;
            // }

            // @include media-breakpoint-down(md) {
            //     padding-left: $sidebar-width * 0.8;
            // }
        }
    }

    @include media-breakpoint-down(lg) {
        width: $sidebar-width * 0.9;
        &.hide {
            left: -$sidebar-width * 0.9;
        }
    }

    // @include media-breakpoint-down(md) {
    @media (max-width: 1150px) {
        width: $sidebar-width * 0.8;
        &.hide {
            left: -$sidebar-width * 0.8;
        }
    }

    + #content,
    ~ #footer {
        // width: calc(100% - #{$sidebar-width});
        padding-left: $sidebar-width;

        @include media-breakpoint-down(lg) {
            padding-left: $sidebar-width * 0.9;
        }

        // @include media-breakpoint-down(md) {
        @media (max-width: 1150px) {
            padding-left: $sidebar-width * 0.8;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 0;
        }
    }

    .arrow {
        position: absolute;
        top: calc(50% - 10px);
        left: 10px;
        // font-size: 15px;
        // font-weight: bold;
        // display: inline-block;
        width: $arrow-widget;
        // vertical-align: middle;
        transition: 0.3s;
        .color1 {
            fill: $arrow;
        }
    }

    /* IE10/11 hack for arrow icon position */
    @media all and (-ms-high-contrast:none) {
        .arrow {
            top: 0;
        }
    }

    .category-header {
        position: relative;
        // background-color: $category-header-background;
        // padding: 14px 15px;
        margin: 3px 0;
        font-family: $title-font-family;
        // font-size: 15px;
        line-height: 1em;
        .category {
            display: inline-block;
            padding: 1.25em 5px 1.25em 30px;
            width: 100%;
            border: none;
            font-size: 1.1em;
            text-align: left;
            vertical-align: middle;
            color: $link;
            font-weight: 500;
            outline: none;
            cursor: pointer;
            text-decoration: none;
            &:hover, &:focus {
                text-decoration: none;
            }

            @include media-breakpoint-down(lg) {
                font-size: 1em;
            }

            // @include media-breakpoint-down(md) {
            @media (max-width: 1150px) {
                font-size: 0.9em;
            }
        }
        .category {
            background-color: $category-header-background;
            &:hover,
            &:focus {
                background-color: $hover-category-header-background;
                .arrow .color1 {
                    fill: $arrow-hover;
                }
            }
            &.active {
                background-color: $active-category-header-background;
                color: $white;
                .color1 {
                    fill: $white;
                }
            }
            &[aria-expanded="true"] {
                > .arrow {
                    transform: rotate(90deg);
                }
            }
            &[aria-expanded="false"] {
                > .arrow {
                    transform: rotate(0deg);
                }
            }
        }
    }

    .secondary-header {
        .category {
            background-color: #dae1ea;
        }
    }

    .subcategory-list {
        .subcategory {
            position: relative;
            margin: 3px 0;
            &:first-child {
                margin: 0; // fixes stutter when expanding/collapsing
            }
            a {
                display: inline-block;
                text-decoration: none;
                padding: 10px 5px 10px 50px;
                width: 100%;
                background-color: $report-list-background;
                color: $link;
                font-size: 1rem;
                font-weight: 500;
                vertical-align: middle;
                &:hover,
                &:focus {
                    background-color: $hover-category-header-background;
                    text-decoration: none;
                }
                &.active {
                    background-color: $active-category-header-background;
                    color: $white;
                    .color1 {
                        fill: $white;
                    }
                }

                @include media-breakpoint-down(lg) {
                    font-size: 0.9rem;
                }

                @include media-breakpoint-down(md) {
                    font-size: 0.8rem;
                }
            }
            .arrow {
                left: 26px;
            }
        }
    }
}

.page-or-category-view {
    #sidebar {
        // box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
    }

    #footer {
        // background-color: #f3f3f3;
        background-color: #e6e6e6;
    }
}

.sidebar-scroll {
    position: relative;
    // top: $header-height;
    // left: 0;
    width: 100%;
    height: calc(100vh - #{$header-height});
    overflow: auto;
    z-index: 20;
}

.sidebar-toggle-wrapper {
    position: absolute;
    left: 100%;
    // transform: rotate(-90deg);
    // transform-origin: right top;
    z-index: 200;
    // top: 54px;
    // border: none;
    // background-color: #95de9d;
    // padding: 2px 8px;
}

.sidebar-toggle {
    margin-top: 3px;
    margin-left: -100%;
    padding: 0px 12px 3px;
    border: none;
    border-radius: 0 0 4px 4px;
    background-color: $grey-medium;
    color: #fff;
    font-weight: 600;
    transform-origin: right top;
    transform: rotate(-90deg);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
    white-space: nowrap;
    &:hover,
    &:focus {
        outline: none;
        background-color: $blue;
    }
}

.menu-icon {
    position: relative;
    top: 4px;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    // transition: 0.3s;
    .color1 {
        fill: #fff;
    }
}

.page {
    width: 100%;
    min-height: 100%;
    margin: 15px 0 0;
    padding: 15px 15px 15px 45px;

    &.row {
        padding-left: 30px;
        padding-right: 0;
    }

    h1 {
        margin-bottom: 30px;

        @include media-breakpoint-down(sm) {
            font-size: 1.8em;
            line-height: 1.2em;
        }

        + h2 {
            margin-top: -30px;
        }
    }
    table {
        // width: 100%;
        background-color: #fff;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin-bottom: 30px;
        a {
            text-decoration: none;
            span {
                text-decoration: underline;
            }
        }
        th,
        td {
            padding: 2px 10px;
        }
        th {
            color: #fff;
            background-color: $blue-dark;
            @include media-breakpoint-down(sm) {
                font-size: 12px;
            }
            &:first-child {
                border-top-left-radius: 5px;
            }
            &:last-child {
                border-top-right-radius: 5px;
            }
        }
        td {
            border-bottom: 1px solid #ddd;
            font-size: 14px;
            @include media-breakpoint-down(md) {
                font-size: 12px;
            }
        }
        tr:first-child {
            td {
                padding-top: 4px;
            }
        }
        tr:last-child {
            td {
                &:first-child {
                    border-bottom-left-radius: 5px;
                }
                &:last-child {
                    border-bottom-right-radius: 5px;
                }
            }
        }
    }

    .last-updated {
        position: relative;
        left: auto;
        bottom: auto;
        margin-top: 4em;
        margin-bottom: 2em;
        color: #6f6f6f;
    }
}

#content {
    position: relative;
    width: 100%;
    // min-height: calc(100vh - #{$header-height});
    min-height: 100vh;
    padding-top: $header-height;
    padding-left: 0;
    background-color: $content-background;
    transition: 0.3s;
}

// global report styles
.widget {
    position: relative;
    background-color: $widget-bg-color;
    &.in-progress {
        .widget-in-progress {
            color: $red;
            font-weight: 700;
            margin-bottom: 0.5em;
        }
    }
}

.widget-header {
    margin-top: 2px;
    width: 100%;
    line-height: 1.5em;
    z-index: 15;
    .widget-accent-button {
        background-color: #e7f1fc;
        color: $primary;
        .color1 {
            fill: $primary;
        }
    }
}

.noData {
    margin: 20px 0;
    padding: 20px 0;
    text-align: center;
    background-color: #E0FAFF;
    font-size: 16px;
    line-height: 2em;
    span {
        font-weight: bold;
    }

}

.widget-title {
    margin-bottom: 0.1em;
    padding-right: 38px;
    color: $blue-dark;
    line-height: 1em;

    @include media-breakpoint-down(lg) {
        font-size: 1.8vw;
    }

    @include media-breakpoint-down(md) {
        font-size: 2.2vw;
    }

    @include media-breakpoint-down(sm) {
        font-size: 3vw;
    }
}

.widget-menu {
    margin-bottom: 4px;
}

.widget-buttons {
    // position: absolute;
    // top: 0;
    // right: 0;
    margin: -$widget-dashboard-padding;
    margin-right: -$widget-dashboard-padding;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    padding: 5px $widget-dashboard-padding;
    min-height: 37px;
    background-color: #eee;
    text-align: right;
    .dropdown {
        display: inline-block;
        .dropdown-toggle:after {
            content: none;
        }
    }
}

.widget-button {
    display: inline-block;
    padding: 3px 8px;
    background-color: #fff;
    color: $grey;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    // letter-spacing: -0.25px;
    font-weight: 500;
    font-family: $title-font-family;
    // text-transform: capitalize;
    transition: 0.3s;
    vertical-align: middle;

    // @include media-breakpoint-down(lg) {
    //     padding: 3px 5px;
    //     font-size: 0.9em;
    // }

    @media only screen and (max-width: 1430px) {
        font-size: 13px;
        padding: 3px 6px;
    }
    @media only screen and (max-width: 1360px) {
        font-size: 12px;
        padding: 3px 4px;
    }
    @media only screen and (max-width: 1280px) {
        padding: 3px 6px;
        // .btn-text {
        //     display: none;
        // }
    }

    &:not(.nohover) {
        cursor: pointer;
        &:hover,
        &:focus {
            background-color: $primary;
            color: $white;
            text-decoration: none;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
            outline: none;
            .widget-button-icon {
                .color1 {
                    fill: #fff;
                }
            }
        }
    }

    &:disabled,
    &.disabled {
        opacity: 0.6;
        &:hover,
        &:focus {
            background-color: #eee;
            color: $grey;
            box-shadow: none;
            .widget-button-icon {
                .color1 {
                    fill: $grey;
                }
            }
        }
    }
}

.widget-button-icon {
    position: relative;
    top: -1px;
    width: 18px;
    height: 18px;
    vertical-align: middle;
    .color1 {
        transition: 0.3s;
        fill: $grey;
    }
}

.widget-button-export {
    .widget-button-icon {
        width: 16px;
        height: 16px;
    }
}

.widget-button-popout {
    .widget-button-icon {
        width: 17px;
        height: 18px;
    }
}

.widget-button-dataset {
    float: left;
    .widget-button-icon {
        margin-left: 20px;
        width: 12px;
        height: 12px;
    }

    &.secondary-dateset {
        margin-right: 4px;
    }
}

.widget .widget-button-popout {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 3px 6px;
}

.btn-group-share {
    display: inline-block;
    vertical-align: baseline;
}

.widget-button-share {
    padding: 2px 6px;
    font-size: 12px;
    line-height: 1em;

    &.highlight {
        &,
        &:hover,
        &:focus {
            color: $green;
            background-color: #d3f5e1;
            .color1 {
                fill: $green;
            }
        }
    }

    .widget-button-icon {
        width: 12px;
        height: 12px;
    }
}

.report-id {
    display: inline-block;
    color: $grey-medium;
    font-size: 12px;
    font-weight: 700;
    line-height: 1em;
    vertical-align: baseline;
}

.datatable-footer  {
    font-weight:bold;
    background-color: lightgray !important;
}

.last-updated {
    position: absolute;
    bottom: 10px;
    left: 10px;
    // display: inline-block;
    color: $grey-medium;
    font-size: 12px;
    font-weight: 700;
    line-height: 1em;
    vertical-align: baseline;
    // float: right;
    margin-top: 5px;
}

.report-help-text {
    color: $blue-dark;
    font-size: 14px;
    font-weight: 700;
    line-height: 1em;
    margin: 20px 0;
}

.report-data-help-text {
    color: #555;
}

.dropdown-menu {
    border: none;
    box-shadow: 0 8px 15px rgba(0,0,0,.33);
    overflow: auto;
    .dropdown-item {
        padding: 0.25rem 1.5rem;
        text-align: left;
    }
}
.report-section-header {
    width: 100%;
}

.report-name {
    display: inline-block;
    // width: 100%;
    margin-left: 15px;
    margin-bottom: 0.75em;
    margin-top: 0.25em;
    line-height: 1.1em;
}

.report-intro-text {
    padding: 0px 15px 20px 15px;
    line-height: 1.3em;
    ul {
        padding-left: 2.5em;
    }
}

#dashboard-view {
    width: 100%;
    min-height: 100%;
    // margin: 15px 0 0;
    margin: 0;
    padding: 15px 15px 15px 25px;
    // z-index: 20;
    .widget {
        margin: 0 0 $widget-spacing;
        padding: $widget-dashboard-padding;
        // box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
        border-radius: 4px;
        // float: left;
        // width: calc(50% - #{$widget-spacing} - (#{$widget-spacing} * 0.5));
        width: 100%;
    }

    .report-footnotes-wrapper {
        max-height: 5rem;
        padding-bottom: 25px;
        overflow: hidden;

        &:before {
            content: "";
            position: absolute;
            bottom: 18px;
            left: 0;
            width: 100%;
            height: 2rem;
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
            pointer-events: none;
            transition: $footnotes-transition;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 18px;
            background-color: #fff;
            pointer-events: none;
            transition: $footnotes-transition;
        }

        &.expanded {
            max-height: none;
            &:before,
            &:after {
                opacity: 0;
            }
        }
    }

    .report-help-text {
        font-size: 12px;
        margin: 15px 0 10px;
    }
}

#single-report-view {
    $padding: 20px;

    background-color: $widget-bg-color;
    width: 100%;
    // height: 100%;
    min-height: calc(100vh - #{$header-height});
    padding: $padding $padding ($padding * 2) ($padding * 2);

    .widget {
        height: 100%;
        width: 100%;
    }

    .widget-header {
        margin-bottom: 10px;
    }

    .widget-title {
        font-size: 28px;
    }

    .report-id {
        font-size: 14px;
    }

    .widget-button-share {
        font-size: 14px;
        vertical-align: baseline;
    }

    .widget-buttons {
        margin-left: -($padding * 2);
        margin-right: -$padding;
        padding-left: $padding * 2;
    }

    .widget-button-popout {
        display: none;
    }

    .report-footnotes-wrapper {
        margin-top: 40px;
        color: #212529;
    }

    .report-footnotes-expand-button {
        display: none;
    }

    .chart-wrapper {
        margin-bottom: 50px;
    }

    .chart {
        // min-height: 65vh;
        min-height: 60vh !important;
        width: 100%;
    }

    .report-data-wrapper {
        width: 100%;
        text-align: center;
    }

    .report-data {
        // position: relative;
        display: inline-block;
        // width: auto;
        // width: 100%;
        min-width: 50%;
    }

    .last-updated {
        position: relative;
        margin-top: 25px;
        left: auto;
        bottom: auto;
    }
}

.mini-report-view {
    margin-bottom: 30px;

    .widget {
        padding: 5px 6px 5px 5px;
        // box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
        border-radius: 4px;
    }

    .widget-title {
        padding-top: 2px;
        padding-left: 5px;
        padding-bottom: 8px;
        font-size: 14px;
    }

    .chart-wrapper {
        margin-bottom: 0;
    }

    .chart {
        min-height: 220px;
        width: 100%;
    }
}


.chart-wrapper {
    position: relative;
    margin-bottom: 20px;
}

.chart {
    min-height: 300px;
    width: 100%;
}

.chart-large {
    // margin-top: -24px;
    min-height: 450px;
}

.chart-reset-btn {
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 10;
    padding: 0 8px;
    background-color: #c54a53;
    color: #fff;

    &:hover,
    &:focus {
        background-color: darken(#c54a53, 15%);
    }
}

// .report-data-wrapper {
//     overflow: auto;
// }
.report-data {
    position: relative;
    // width: 100%;
    width: auto;
    max-width: 100%;
}

.report-footnotes-wrapper {
    position: relative;
    margin-top: 20px;
    line-height: 1rem;
    font-size: 12px;
    color: $grey-medium;
    transition: $footnotes-transition;
}

.report-footnote {
    margin: 0.2rem 0;
    a {
        text-decoration: none;
    }
}

.report-footnotes-expand-button {
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 72px;
    z-index: 10;
    border: none;
    background: #fff;
    color: $blue;
    box-shadow: 0 0 10px #fff;
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;
    font-weight: 500;
    &:hover,
    &:focus {
        outline: none;
    }
}

.modal-title {
    font-size: 20px;
    color: $blue-dark;
    // width: calc(100% - 110px);
    // width: calc(100% - 140px);
}

.modal-dialog {
    // max-width: calc(100vw - 20px);
    min-width: 40vw;
    max-width: 80vw;
}

.modal-header {
    .close {
        float: right;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #FFFFFF;
        opacity: .5;
        background-color: transparent;
        outline: none;
        border: 0;
    }
}

.modal-body {
    .widget-buttons {
        margin: -1rem -1rem 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .report-data.multiple {
        display: block;
        width: 100%;
        overflow: auto;
    }
}

:not(.modal-body) {
    .report-data-wrapper {
        margin-bottom: 20px;
    }
}

.search-highlight {
    // padding: 0 3px;
    // background-color: lighten($blue, 65%);
    // color: $blue;
    background-color: #faecaf;
    color: #000;
    font-weight: 500;
    border-radius: 3px;
}

.tableexport-caption {
    width: 100%;
    padding: 0;
    .btn-default {
        padding: 0.25rem 1.5rem;
        width: 100%;
        text-align: left;
        margin: 0 !important;
        &:hover, &:focus, &:active {
            box-shadow: none;
            color: #16181b;
            text-decoration: none;
            background-color: #f8f9fa;
        }
    }
}

.collapse-group {
    margin-bottom: 20px;
    .card {
        font-size: 16px;
        transition: 0.3s;
        border: none;
        // border-radius: 0 0 5px 5px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
}

.btn-collapse {
    position: relative;
    padding: 14px 10px;
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    color: $gray-dark;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 2;
    white-space: normal;
    &:not(.collapsed) {
        // border-bottom-left-radius: 0;
        // border-bottom-right-radius: 0;
        .collapse-icon {
            transform: rotate(90deg);
        }
    }
    &:not(.collapsed),
    &:hover,
    &:focus {
        background-color: $primary;
        color: $white;
        text-decoration: none;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        outline: none;
        .collapse-icon {
            .color1 {
                fill: #fff;
            }
        }
    }
}

.btn-collapse-text {
    display: inline-block;
    width: calc(100% - 30px);
    line-height: 1.2em;
    vertical-align: middle;

    @include media-breakpoint-down(sm) {
        font-size: calc(1vw + 10px);
    }
}

.collapse-icon {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    transition: 0.3s;
    .color1 {
        fill: $blue;
    }
}

.collapse,
.collapsing {
    position: relative;
    z-index: 1;
    // .card {
    //     border: none;
    //     // border-radius: 0 0 5px 5px;
    //     box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    //     border-radius: 4px;
    // }
}

.tooltip-inner {
    text-align: left;
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        margin-top: 0.3em;
        line-height: 1.2em;
    }
}

#footer {
    margin: 0;
    padding: 20px 0 40px;
    // background-color: #f3f3f3;
    text-align: center;
    transition: 0.3s;

    ul {
        margin: 0 30px 20px 40px;
        // padding: 15px 0 0;
        padding: 0;
        list-style: none;

        // &:first-child {
        //     border-top: 2px solid #e0e0e0;
        // }
    }

    li {
        display: inline-block;
    }

    &,
    a {
        // color: #717171;
        color: #555;
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
    }

    a {
        padding: 2px 5px;
    }

    .footer-tagline {
        font-weight: normal;
    }
}

#app.chart-only {
    // @media only screen and (max-width: 768px) {
    height: 100%;
    // }
}

.chart-only {
    #main {
        height: 100%;
    }
    #content {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 0;
        height: 100%;
        min-height: 100%;
    }
    #single-report-view {
        padding: 0;
        height: 100%;
        min-height: 100%;
        .chart-wrapper {
            margin-bottom: 0;
            height: 100%;
            > div {
                height: 100%;
            }
        }
        .chart {
            height: 100%;
            min-height: 0;
        }
    }
    .legends {
        margin-bottom: 0;
        padding-bottom: 4px;
    }
}

.application-msg {
    .close {
        position: absolute;
        right: 10px;
        top: 7px;
        z-index: 101;
        opacity: 1;
        color: #444;
    }
}


.data-box {
    background-color: #fff8d3;
    text-align: center;
    margin: 10px 0;
    padding: 10px;
    height: 100%;
    .card-title {
        margin-top: 20px;
        height: 50px;
        font-size: 16px;
    }
    .card-text {
        font-size: 18px;
        color: #865105
    }
}