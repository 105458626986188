
              @import "@/scss/variables.scss";
          

.download-list {
    list-style: none;
    margin: 30px 0;
    padding: 0;

    .heading {
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 0;
        font-size: 18px;
        color: #333;
        vertical-align: top;
    }

    .sub-heading {
        display: block;
        margin-top: -4px;
        font-size: 12px;
        color: #6d6d6d;
        font-weight: 600;
    }

    > li {
        margin-bottom: 25px;
    }
}

.download-buttons {
    display: inline-block;
    a {
        text-decoration: none;
    }
}

.btn-download {
    display: inline-block;
    padding: 6px 10px;
    background-color: #fff;
    color: #ad1700;
    // border: 1px solid #ab1800;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
    vertical-align: top;

    + .btn-download {
        margin-left: 6px;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    }

    img {
        margin-right: 5px;
    }
}
