
              @import "@/scss/variables.scss";
          

    #report-search {
        position: relative;
        float: right;
        .search-wrapper {
            @media only screen and (max-width: 768px) {
                display: block;
                position: fixed;
                margin: 0;
                padding: 7px 10px;
                width: 100vw;
                left: 0;
                top: 44px;
                background-color: #043e65;
                #search-input {
                    width: 100%;
                }
                .search-button {
                    top: 7px;
                    right: 10px;
                }
                .search-results-list {
                    width: 100%;
                }
            }
        }
        .search-button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 4px 6px;
            height: 31px;
            background: none;
            border: none;
            outline: none;
            pointer-events: none;
            z-index: 6;
            // &:hover,
            // &:focus {
            //     .search-icon {
            //         .color1 {
            //             fill: $blue;
            //         }
            //     }
            // }
            // @media only screen and (max-width: 768px) {
            //     pointer-events: auto;
            // }
            &.search-button-mobile {
                pointer-events: auto;
            }
        }
        .search-icon {
            width: 23px;
            height: 23px;
            .color1 {
                fill: $hover-category-header-background;
                transition: 0.3s;
            }
        }
    }
    #search-input {
        position: relative;
        width: 300px;
        border: none;
        border-radius: 3px;
        padding: 0.35em 0.5em;
        transition: 0.3s;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        outline: none;
        z-index: 5;

        &:focus {
            // width: 300px;
            ~ .search-button {
                pointer-events: all;
                .search-icon {
                    .color1 {
                        fill: darken($hover-category-header-background, 20%);
                    }
                }
                &:hover,
                &:focus {
                    .search-icon {
                        .color1 {
                            fill: $active-category-header-background;
                        }
                    }
                }
            }
            ~ .search-results-list:not(:hover) {
                ul {
                    li:first-child {
                        .search-result-item {
                            color: $blue-dark;
                            background-color: $hover-category-header-background;
                            background-color: lighten($hover-category-header-background, 5%);
                            .report-id {
                                color: $grey;
                            }
                        }
                    }
                }
            }
        }
    }
    .search-results-list {
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: -3px;
        width: 300px;
        max-height: 50vh;
        min-height: 200px;
        color: #000;
        background-color: #fff;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
        overflow: auto;
        border-radius: 0 0 5px 5px;
        z-index: 1;
        transition: 0.3s;
        &.collapse {
            min-height: 0;
            height: 0;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li {
            &:first-child {
                .search-result-item {
                    padding-top: 10px;
                }
            }
        }
        .search-result-item {
            display: block;
            padding: 5px 10px;
            text-align: left;
            background: none;
            border: none;
            width: 100%;
            outline: none;
            &:hover,
            &:focus {
                text-decoration: none;
                color: $blue-dark;
                background-color: lighten($hover-category-header-background, 5%);
                .report-id {
                    color: $grey;
                }
            }
            .report-name {
                display: block;
                line-height: 1em;
                margin-bottom: 0.15em;
                margin-left: 0;
            }
            .report-id {
                display: block;
            }
        }
    }
