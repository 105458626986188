
              @import "@/scss/variables.scss";
          

    .callout-box {
        margin: 0 0 25px;
        padding: 8px 10px 8px;
        background-color: #f5fff5;
        /* box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15); */
        border-radius: 4px;

        h2 {
            // margin-bottom: 0.1em;
            font-size: 18px;
            color: #378037;
            line-height: 1.2em;
        }

        a {
            // color: #000;
            // color: #1a5e1a;
            // font-weight: 700;
            // text-decoration: underline;
        }

        .link-text {
            color: #212529;
        }

        .icon-exit-link {
            position: relative;
            top: -2px;
            margin-left: 4px;
            width: 18px;
            height: 18px;
            vertical-align: middle;
        }

        .new-reports-list {
            a {
                display: block;
                text-decoration: none;
                padding: 0.25em 0.5em;
                margin-left: -0.5em;
                margin-top: 0.75em;
                margin-bottom: 0.75em;
                width: calc(100% - 0.5em);
                // font-size: 13px;
                font-weight: 500;
                line-height: 1.2em;
                background-color: #fff;
                color: #555;
                border-radius: 4px;
                // box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
                box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
                transition: 0.3s;
                // border-radius: 5px;
                // border-left: 3px solid $green-light;
                &:hover,
                &:focus {
                    // background-color: $blue-lightest;
                    color: $green;
                    text-decoration: none;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
                }
            }
        }


        // .feedback-link {
        //     color: inherit;
        //     text-decoration: underline;
        // }
    }

