
              @import "@/scss/variables.scss";
          

$loaderBackgroundColor: #ccc;
$loaderColor: #0f3068;

.table-loader {

    border-top: 1.1em solid $loaderBackgroundColor;
    border-right: 1.1em solid $loaderBackgroundColor;
    border-bottom: 1.1em solid $loaderBackgroundColor;
    z-index: 100;
    text-indent: 0;
    color: transparent;
}
.loader {
    position: absolute;
}
.loader {
    color: #cecece;
    font-size: 3px;
    left: 60%;
    top: 35%;
    text-indent: -9999em;
    border-top: 1.1em solid $loaderBackgroundColor;
    border-right: 1.1em solid $loaderBackgroundColor;
    border-bottom: 1.1em solid $loaderBackgroundColor;
    border-left: 1.1em solid $loaderColor;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    z-index: 1;
    overflow: hidden;
}
.loader, .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
