table {
    td {
        height: 1.8em;
        font-weight: 400;
        font-size: 12px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        vertical-align: top;
        line-height: 1.2em;
    }

    + table {
        margin-top: 40px;
    }
}

.top-header-row {
    th[colspan]:not(.no-background) {
        // background: #777;
        // padding: 3px 4px;
        padding: 0;
        // border-left: 4px solid #555;
        // &:nth-child(2n) {
        //     background-color: rgb(81, 105, 114);
        // }
        &.header-cell {
            padding: 3px 0;
        }

        .header-cell {
            padding: 3px 5px;
            // margin-left: 2px;
            background: #777;
            width: calc(100% - 4px);
            text-align: center;
        }
    }
}

.slide-panel-link,
.back-btn {
    background-color: #f2f2f2;
    color: $primary;
    transition: 0.3s;
    text-decoration: none;
    padding: 2px 6px;
    min-width: 30px;
    text-align: center;
    border-radius: 3px;
    &:hover {
        text-decoration: none;
        background-color: #fff;
        color: $primary;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.33);
    }
}

.slide-panel-link {
    display: inline-block;
    // background-color: #f2f2f2;
    // color: $primary;
    // background-color: #fff;
    // color: #015496;
    // text-decoration: none;
    // padding: 2px 6px;
    // min-width: 30px;
    // display: inline-block;
    // text-align: center;
    // border-radius: 3px;
    // border: 1px solid #D0D0D0;
    // transition: 0.3s;

    // &:hover {
    //     text-decoration: none;
    //     background-color: #fff;
    //     color: $primary;
    //     // background-color: $primary;
    //     // color: #fff;
    //     // border-color: #015496;
    //     box-shadow: 0 2px 6px rgba(0, 0, 0, 0.33);
    // }

    &:after {
        content: "\25B8";
        color: $primary;
        // color: #fff;
        right: -3px;
        position: relative;
        font-size: 14px;
    }
}

.table-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    // height: calc(100% - 200px);
    // top: 200px;
    left: 0;
    overflow: visible;
    transition: .3s;

    table {
        width: 100%;
        overflow: auto;
    }
}

.header {
    position: relative;
    // padding-right: 50px;
    // padding-left: 5px;
    // cursor: pointer;
    vertical-align: top;
    background: #555;
    color: #fff;
    font-family: $title-font-family;
    font-size: 13px;
    font-weight: 700;
    line-height: 1em;
    height: 100%;

    .sort-controls {
        position: relative;
        float: left;
        height: 100%;
        padding: .5em 18px .5em 5px;
        cursor: pointer;
        button {
            position: absolute;
            top: 5px;
            transition: 0.3s;
        }
        &:hover:not(.no-sort),
        &:focus:not(.no-sort) {
            background-color: $grey-medium;
        }

        &.no-sort {
            cursor: default;
            padding-right: 0;
        }
    }

    .sort-icons {
        // padding: 0 2px;
        // outline: none;
        position: absolute;
        top: 0;
        // right: 0;
        right: 12px;
        .widget-settings-icon-sort-down, .widget-settings-icon-sort-up {
            width: 8px;
            height: 8px;
            position: absolute;
            .color1 {
                fill: #9e9c9c;
            }
        }

        .widget-settings-icon-sort-up {
            top: 5px;
        }

        .widget-settings-icon-sort-down {
            top: 12px;
        }
    }

    &.asc,
    &.desc {
        background-color: lighten(#555, 5%);
        .sort-icons {
            opacity: 1;

            .widget-settings-icon-sort-down {
                .color1 {
                    fill: #dedada;
                }
            }
            .widget-settings-icon-sort-up {
                .color1 {
                    fill: #9e9c9c;
                }
            }
        }

        &.asc {
            .widget-settings-icon-sort-down {
                .color1 {
                    fill: #9e9c9c;
                }
            }
            .widget-settings-icon-sort-up {
                .color1 {
                    fill: #dedada;
                }
            }
        }
    }
}

.no-data-text {
    top: 90px !important;
    position: absolute;
    z-index: 1;
    left: 10px;
}
.back-btn {
    // background-color: #fff;
    // color: #015496;
    // text-decoration: none;
    // padding: 2px 6px;
    // min-width: 30px;
    // display: inline-block;
    // text-align: center;
    // border-radius: 3px;
    // border: 1px solid #D0D0D0;
    // -webkit-transition: 0.3s;
    // transition: 0.3s;

    // &:hover {
    //     text-decoration: none;
    //     border-color: #015496;
    // }

    &:before {
        content: "\25C0";
        color: $primary;
        right: 3px;
        position: relative;
        font-size: 11px;
        top: -1px;
    }
}

.col-niesi {
    position: relative;
}

svg {
    fill: $primary;

    &.niesi-icon-ni {
        fill: $green;
    }

    &.niesi-icon-esi {
        fill: $blue;
    }

    &.niesi-icon-fr {
        fill: $grey-medium-light;
    }
    &.niesi-icon-fr-circle {
        position: absolute;
        bottom: 4px;
        left: 17px;
        width: 14px;
        height: 14px;
        pointer-events: none;
        .color1 {
            fill: $yellow;
        }
    }

}

.col-status {
    > div {
        display: table-cell;
        padding: 2px 4px;
        width: 1.8em;
        height: 1.8em;
        border-radius: 2px;
        line-height: 1em;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        font-weight: 500;
    }
    // .status- {
    //     background-color: $primary;
    // }
    .status-p,
    .status-pc,
    .status-pi,
    .status-pa,
    .status-pirg {
        background-color: #C66E1E;
    }
    .status-rr {
        background-color: #DB9C27;
    }
    .status-tp {
        background-color: #3DA1AD;
    }
    .status-a,
    .status-t {
        background-color: #308016;
    }
    .status-w {
        background-color: #A41E46;
    }
    .status-u {
        background-color: #6A6A6A;
    }
}
